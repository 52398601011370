/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-21 16:41:17 
 * @Last Modified by: Chen Hao
 * @Last Modified time: 2024-12-3 21:49:08
 */

<template>
    <a-modal
      :open="open"
      :width="600"
      class="image-upload"
      title="Cropper Image"
      @cancel="onCancel"
    >
      <template #footer>
        <div class="btn">
          <artmisads-button :loading="loading" @click="onCancel">Cancel</artmisads-button>
          <artmisads-button :loading="loading" type="primary" @click="onSaveImg">Confirm</artmisads-button>
        </div>
      </template>
      <div
        class="crop-wrapper"
        :style="{ width: `${imgObj.width}px`, height: `${imgObj.height}px` }"
      >
        <VueCropper
          ref="cropper"
          :img="option.img"
          :output-size="option.outputSize"
          :output-type="option.outputType"
          :can-scale="option.canScale"
          :auto-crop="option.autoCrop"
          :full="option.full == '0'"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :can-move-box="option.canMoveBox"
          :can-move="option.canMove"
          :center-box="option.centerBox"
          :info="option.info"
          :fixed-box="option.fixedBox"
          :fixed="option.fixed"
          :fixed-number="option.fixedNumber"
        />
      </div>
      <footer style="margin-bottom: 10px;">You can zoom the picture by scrolling the mouse wheel.</footer>
    </a-modal>
</template>

<script setup>
import { ref, defineProps, watch, reactive } from 'vue'
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css' // vue3才需要引入样式，vue2不要
import adminApi from '../server/admin';

const props = defineProps(['coverFile', 'open'])
const emit = defineEmits(['cropped-file', 'onCancel']);
const option = reactive({
  img: props.coverFile, // 裁剪图片地址
  outputSize: 1, // 裁剪生成图片质量
  outputType: 'jpeg', // 裁剪生成图片格式
  canScale: true, // 图片是否允许滚轮播放
  autoCrop: true, // 是否默认生成截图框 false
  info: true, // 是否展示截图框信息
  autoCropWidth: 200, // 生成截图框的宽度
  autoCropHeight: 200, // 生成截图框的高度
  enlarge: 1, // 采集后放大倍数
  full: '0', // 是否开启原尺寸裁剪 0 原尺寸 1当前尺寸
  infoTrue: true,
  original: true,
  canMoveBox: true, // 截图框是否可以拖动
  fixedBox: true, // 固定截图框的大小
  fixed: false, // 是否开启截图框宽高固定比例
  // fixedNumber: [2.35, 1], // 截图框的宽高比例 默认2.35, 1:1,
  canMove: true, // 上传图片是否可拖动
  centerBox: true, // 截图框限制在图片里面
  accept: 'image/jpeg,image/jpg,image/png,image/gif,image/x-icon',
})
const imgObj = ref({
  width: 552,
  height: 310,
})

const open = ref(props.open);
const cropper = ref(null);
const loading = ref(false);

watch(
  () => props.open,
  (newValue) => {
    open.value = newValue;
  }
)

watch(
  () => props.coverFile,
  (newValue) => {
    option.img = newValue;
  }
)

const onCancel = () => {
  emit('onCancel')
}

const onSaveImg = () => {
  if (cropper.value) {
    loading.value = true;
      cropper.value.getCropBlob((blob) => {
        let data = {
          file: blob
        }
        adminApi.uploadImg(data).then((res) => {
          console.log('upload-success',res);
          emit('cropped-file',res)
          loading.value = false;
          open.value = false;
        }).catch(e => {
          console.log(e)
        })
        console.log(blob);
      });
  }
}
</script>

<style lang="less" scoped>
.image-upload {
  :deep(.ant) {
    &-modal-close {
      top: 23px;
    }
    &-modal-body {
      border: none;
    }
    &-modal-title {
      display: flex;
      padding-right: 30px;
      justify-content: space-between;
    }
    &-btn {
      height: 38px;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      padding-left: 29px;
      border-radius: 20px;
      padding-right: 29px;
      letter-spacing: 0.4px;
      font-family: Sora-Medium;
      box-shadow: 0 1px 0 0 #1a16250d;
      &-default {
        opacity: 0.7;
        font-size: 15px;
        font-weight: 500;
        color: #0f0629;
        text-align: center;
        margin-right: 15px;
        letter-spacing: 0.4px;
        background: #ffffff;
        font-family: Sora-Medium;
        border: 1px solid #b4b0bf;
      }
    }
  }
  .crop-wrapper {
    overflow: hidden;
    position: relative;
    background: #eee;
  }
}
footer {
  color: #999;
  margin-top: 12px;
  text-align: center;
  margin-bottom: -12px;
}
</style>
